document.addEventListener('DOMContentLoaded', function(){
    $(".expand-toggle").on("click", function (e) {
        e.preventDefault();
        $(this).prev().toggleClass("show");
        if ($(".expand-toggle").eq(0).find("strong").text() === "Xem thêm") {
            $(this).find("strong").toggleText("Xem thêm", "Rút gọn");
        } else {
            $(this).find("strong").toggleText("View more", "View less");
        }
        $(this).find("span").toggleClass("mdi-chevron-down mdi-chevron-up");
    });
    $(window).scroll(function () {
        if ($(this).scrollTop()) {
            $(".back-to-top").show();
        } else {
            $(".back-to-top").hide();
        }
    });
    $(".change-url").on("change", function () {
        window.location.assign($(this).val());
    });
    toggleView()

    var vid = document.querySelector(".video video");
    if(vid) {
        try {
            vid.onended = function () {
                // console.log("The video is ended");
                // $(".home-banner .uk-slidenav-next").click("");
                UIkit.slideshow(".home-banner .uk-slideshow").startAutoplay();
                // vid.currentTime = "0";
            };
        } catch (error) {}
        UIkit.util.on(".home-banner .uk-slideshow", "itemshow", function () {
            if (vid.ended) {
                UIkit.slideshow(".home-banner .uk-slideshow").startAutoplay();
                console.log("ended");
            } else {
                UIkit.slideshow(".home-banner .uk-slideshow").stopAutoplay();
                console.log("not ended");
            }
        }); 
    } else {
        UIkit.slideshow(".home-banner .uk-slideshow").startAutoplay();
    }
});

$.fn.extend({
    toggleText: function (a, b) {
        return this.text(this.text() == b ? a : b);
    },
});

var ua = window.navigator.userAgent;
var isIE = ua.indexOf("Trident/");

if (isIE < 1) {
    const menuMobileMapping = new MappingListener({
        selector: ".main-menu",
        mobileWrapper: ".mobile-menu",
        mobileMethod: "appendTo",
        desktopWrapper: ".bottom-header-wrapper",
        desktopMethod: "prependTo",
        breakpoint: 1024.98,
    }).watch();

    const topMenuMobileMapping = new MappingListener({
        selector: ".top-menu",
        mobileWrapper: ".mobile-menu",
        mobileMethod: "appendTo",
        desktopWrapper: ".top-header-wrapper",
        desktopMethod: "prependTo",
        breakpoint: 1024.98,
    }).watch();
}

var detailThumbnailSwiper = new Swiper(".detail-thumbnail .swiper-container", {
        spaceBetween: 10,
        observer: !0,
        observeParents: !0,
        slidesPerView: 5,
        breakpoints: {
            576: {
                slidesPerView: 3,
            },
        },
    }),
    detailImageSwiper = new Swiper(".detail-images .swiper-container", {
        spaceBetween: 10,
        observer: !0,
        observeParents: !0,
        thumbs: {
            swiper: detailThumbnailSwiper,
        },
        navigation: {
            prevEl: ".detail-images .button-prev",
            nextEl: ".detail-images .button-next",
        },
    }),
    yearDotSwiper = new Swiper(".cot-moc .year-dot .swiper-container", {
        spaceBetween: 10,
        observer: !0,
        observeParents: !0,
        slidesPerView: 7,
        breakpoints: {
            576: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 5,
            },
        },
        navigation: {
            prevEl: ".year-dot .slider-prev",
            nextEl: ".year-dot .slider-next",
        },
    }),
    yearContentSwiper = new Swiper(".cot-moc .year-content .swiper-container", {
        spaceBetween: 10,
        observer: !0,
        observeParents: !0,
        thumbs: {
            swiper: yearDotSwiper,
        },
    }),
    yearTitleSwiper = new Swiper(
        ".gioi-thieu-chung-3 .year-title .swiper-container",
        {
            spaceBetween: 10,
            observer: !0,
            observeParents: !0,
            slidesPerView: 4,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },
                576: {
                    slidesPerView: 2,
                },
            },
            navigation: {
                prevEl: ".year-title .slider-prev",
                nextEl: ".year-title .slider-next",
            },
        }
    ),
    yearContentSwiper2 = new Swiper(
        ".gioi-thieu-chung-3 .year-content .swiper-container",
        {
            spaceBetween: 10,
            observer: !0,
            observeParents: !0,
            thumbs: {
                swiper: yearTitleSwiper,
            },
            on: {
                transitionEnd: function () {
                    $(".expand-content").removeClass("show");
                    $(".expand-toggle").find("strong").text("Xem thêm");
                    $(".expand-toggle")
                        .find("span")
                        .removeClass("mdi-chevron-up")
                        .addClass("mdi-chevron-down");
                },
            },
        }
    ),
    bannerSlider = new Swiper(".banner-slider .swiper-container", {
        slidesPerView: 6,
        spaceBetween: 30,
        slidesPerColumn: 2,
        breakpoints: {
            1024: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
        },
        navigation: {
            prevEl: ".banner-slider .slider-prev",
            nextEl: ".banner-slider .slider-next",
        },
    });

function toggleView() {
    $(".toggle-view-wrapper").each(function () {
        let x = 6;
        $(this).find(".wrapper").slice(0, x).show();
        let size = $(this).find(".wrapper").length;
        console.log(size);
        if (size <= x) {
            $(this).parent().find(".btn-view-more").addClass("uk-hidden");
        }
        $(".toggle-view-wrapper .btn-view-more")
            .on("click", function (e) {
                e.preventDefault();
                x = x + 6;
                $(this)
                    .parents(".toggle-view-wrapper")
                    .find(".wrapper")
                    .slice(0, x)
                    .slideDown();
                if (x >= size || size < x) {
                    $(this)
                        .parent()
                        .find(".btn-view-more")
                        .addClass("uk-hidden");
                }
            });
    });
}
